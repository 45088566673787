/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you’re the kind of person who loves to hunt in the Alleghenies, a sports fan who cheers on the Eagles or the Steelers, or a hardcore NASCAR fan who loves soaking up the atmosphere at race days, your ears really make a difference in Pennsylvania life. It’s sometimes easy to forget how much we rely on our sense of hearing in everyday life, from simple conversations to hearing safety instructions – and that’s no different in the Keystone State. So, be sure to act fast if you feel you’re having trouble hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ensure-that-pennsylvania-sounds-as-good-as-ever-for-as-long-as-possible",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ensure-that-pennsylvania-sounds-as-good-as-ever-for-as-long-as-possible",
    "aria-label": "ensure that pennsylvania sounds as good as ever for as long as possible permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ensure that Pennsylvania sounds as good as ever, for as long as possible"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And it’s well worth acting sooner, rather than later. Just think about all the experiences Pennsylvania residents enjoy, where hearing is a huge part of the fun. What would it be like to attend a Steeler’s game at Heinz Field and not be able to hear the crowd noise boom as touchdowns are scored? Imagine being at the NASCAR event in Philadelphia’s Center City, unable to hear the engines reverberating around the city streets, or visiting the Philadelphia Zoo without being able to hear the chimps, elephants (or the sound of youngsters encountering animals for the first time). And what would watching Philly movies like Rocky be like without the pumping soundtrack?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In truth, so much of life in Pennsylvania revolves around our ears. You could be hunting for deer in the Allegheny National Forest and need to hear every single sound in the brush. Or you could be playing golf and need to hear the shouts of “fore” as balls whizz towards you. And that’s before you start to consider everyday life. When your hearing starts to fail, your ability to maintain conversations and enjoy music declines, impacting your social and professional life. Wherever you are in America, that doesn’t have to be the case. But in Pennsylvania – a state of musical history, sports and outdoor recreation – it’s a really big deal. So, doing something about it is a great call."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "getting-a-grip-on-pennsylvanias-hearing-situation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getting-a-grip-on-pennsylvanias-hearing-situation",
    "aria-label": "getting a grip on pennsylvanias hearing situation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Getting a grip on Pennsylvania's hearing situation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think that you are experiencing hearing loss, you’re not the only one. In fact, there’s a good chance that people you know and work with are having similar problems. About 48 million Americans experience hearing loss (which works out to about 2.5 million Keystone State natives). And that increases to a third of Americans over the age of 65."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After a lifetime of working in loud environments, attending music/sports venues or hunting with guns, millions of Pennsylvania residents are left with impaired hearing. And that’s before we talk about people with hearing-related conditions like tinnitus, which can also be handled with simple interventions. So, across the state, there’s a huge community of people with hearing loss. If you’re one of them, or you fear that you might be soon, there’s no need to be afraid or feel alone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "take-action-to-deal-with-hearing-loss-in-pennsylvania-with-hearcoms-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-action-to-deal-with-hearing-loss-in-pennsylvania-with-hearcoms-help",
    "aria-label": "take action to deal with hearing loss in pennsylvania with hearcoms help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take action to deal with hearing loss in Pennsylvania with hear.com's help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All over the state of Pennsylvania, you’ll find dedicated, professional hearing experts who are more than happy to assist you with all your hearing needs. At Hear.com, we have created a network of these professionals and we would love to get in touch and arrange a free consultation with you. We have Partner Providers all over the state, so you don’t need to worry about traveling long distances to have your ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing tested"), ". If you’re in the far north-west near Erie, there should be experts nearby, and the same applies for Pittsburgh, Harrisburg, Wilkes-Barre, Scranton and Philadelphia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You’ll be able to choose from hearing aids that are on the cutting edge of today’s technology. And with a 45-day risk-free trial period, you can ensure that your hearing aid works perfectly before you finalize the purchase. That way, you can nip hearing problems in the bud, well before they become a major issue."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "arrange-a-risk-free-trial-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrange-a-risk-free-trial-today",
    "aria-label": "arrange a risk free trial today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Arrange a Risk-Free Trial Today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To start tackling your hearing aid issues, just visit our website and fill out our questionnaire. After adding a few pieces of information about contact details and your current hearing situation, we’ll connect you with a local audiologist and schedule an appointment in seconds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After that, you’ll be able to find the perfect solution with the assistance of Florida’s leading hearing loss professionals."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
